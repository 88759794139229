app.factory('excelExportService', ['$rootScope', '$q', '$cookies', '$http', function ($rootScope, $q, $cookies, $http)
{

    var excelExportServiceFactory = {};

    var _parseUrl = function (url)
    {
        url = typeof (url) == "function" ? url() : url;

        var parser = document.createElement('a'),
            searchObject = {},
            queries, split, i;
        // Let the browser do the work
        parser.href = url;
        // Convert query string to object
        if (parser.search)
        {    //if search string is not empty
            queries = parser.search.replace(/^\?/, '').split('&');
            for (i = 0; i < queries.length; i++)
            {
                split = queries[i].split('=');
                searchObject[split[0]] = split[1];
            }
        }
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            searchObject: searchObject,
            hash: parser.hash
        };
    }

    var _isNumber = function (o)
    {
        if (typeof o !== "undefined" && o !== null && o !== "")
        {
            if (!isNaN(+o) && isFinite(+o))
            {
                return true;
            }
        }

        return false;
    };

    var _exportToExcel = function (displayGrid, excelFileName, isApplyWorkaroundForMasterSetupExport, isReadFromServer, detailGridNameForAPIExpand, isDisableFieldGroupingByUID, isHideDetailGrid, isAlignHeaderCenter, isFormatFooter) {

        if (typeof isApplyWorkaroundForMasterSetupExport !== "boolean")
        {
            isApplyWorkaroundForMasterSetupExport = false;
        }

        if (typeof isReadFromServer !== "boolean")
        {
            isReadFromServer = true;
        }

        if (isReadFromServer === false)
        {
            try
            {
                //remove sorting as it mess up the ordering, follow back data's ordering
                displayGrid.dataSource._sort = void 0;
            }
            catch { }
        }

        if (typeof isDisableFieldGroupingByUID !== "boolean")
        {
            isDisableFieldGroupingByUID = false;
        }

        if (typeof isHideDetailGrid !== "boolean") {
            isHideDetailGrid = false;
        }

        if (typeof isAlignHeaderCenter !== "boolean") {
            isAlignHeaderCenter = false;
        }
        if (typeof isFormatFooter !== "boolean") {
            isFormatFooter = false;
        }
        

        var displayGridOptions = displayGrid.getOptions();

        //title: "Print"

        var displayGridNoOfColumn = displayGridOptions.columns.length;
        for (var i = 0; i < displayGridNoOfColumn; i++)
        {
            if (displayGridOptions.columns[i].title === "Print")
            {
                displayGridOptions.columns.splice(i, 1);
                i = i - 1;
                displayGridNoOfColumn = displayGridNoOfColumn - 1;

                if (displayGridOptions.dataSource.schema.model.fields.IsSelect)
                {
                    delete displayGridOptions.dataSource.schema.model.fields.IsSelect;
                }
            }
        }

        for (var i = 0; i < displayGridNoOfColumn; i++)
        {
            //to workaround invalid template generated when style is conditionally applied and column is hidden
            if (displayGridOptions.columns[i].hidden === true
                && typeof displayGridOptions.columns[i]?.attributes?.style === "string"
                && (displayGridOptions.columns[i]?.attributes?.style).includes("display:none")
                && (displayGridOptions.columns[i]?.attributes?.style).includes("#="))
            {
                //truncate out the condition
                displayGridOptions.columns[i].attributes.style =
                    //front half
                    (displayGridOptions.columns[i]?.attributes?.style).substr(0, (displayGridOptions.columns[i]?.attributes?.style).indexOf("#="))
                    //back half
                    + (displayGridOptions.columns[i]?.attributes?.style).substr((displayGridOptions.columns[i]?.attributes?.style).indexOf("#", (displayGridOptions.columns[i]?.attributes?.style).indexOf("#=") + 2) + 1)
            }
        }

        if (isApplyWorkaroundForMasterSetupExport)
        {
            //to fix missing field when it is checkbox for "Active"
            var displayGridNoOfColumn = displayGridOptions.columns.length;
            for (var i = 0; i < displayGridNoOfColumn; i++)
            {
                if (typeof displayGridOptions.columns[i].field === "undefined")
                {
                    if (typeof displayGridOptions.columns[i].template !== "undefined")
                    {
                        if (typeof displayGridOptions.columns[i].template == "string")
                        {
                            let text = displayGridOptions.columns[i].template.replace(/\s/g, '');

                            let labelkeystart = text.indexOf('#=');
                            let labelkeylast = text.lastIndexOf('?"checked=checked"');

                            if (labelkeystart > -1 && labelkeylast > -1 && labelkeystart < labelkeylast)
                            {
                                let labelkey = text.substring(labelkeystart + 2, labelkeylast);

                                if (typeof labelkey !== "undefined" && !labelkey.contains("<") && labelkey !== "")
                                {
                                    displayGridOptions.columns[i].field = labelkey;
                                }
                            }
                        }
                    }
                }
            }

            //to fix title
            for (var i = 0; i < displayGridNoOfColumn; i++)
            {
                if (typeof displayGridOptions.columns[i] !== "undefined")
                {
                    if (typeof displayGridOptions.columns[i].title !== "undefined")
                    {
                        if (typeof displayGridOptions.columns[i].title !== "")
                        {
                            if (displayGridOptions.columns[i].title.contains("{{") && displayGridOptions.columns[i].title.contains("}}"))
                            {
                                let labelkeystart = displayGridOptions.columns[i].title.indexOf("'");
                                let labelkeylast = displayGridOptions.columns[i].title.lastIndexOf("'");

                                if (labelkeystart > -1 && labelkeylast > -1 && labelkeystart != labelkeylast)
                                {
                                    let labelkey = displayGridOptions.columns[i].title.substring(labelkeystart + 1, labelkeylast);
                                    displayGridOptions.columns[i].title = $rootScope.label[labelkey.toString()][$rootScope.userSetting.langKey].LabelDesc;
                                }
                            }
                        }
                    }
                }
            }
        }

        var detailGridOptions = isHideDetailGrid === true? void 0: displayGridOptions.detailOptions;

        if (typeof displayGridOptions.detailOptions !== "undefined")
            if (typeof displayGridOptions.detailOptions.length == "undefined")
            {
                displayGridOptions.detailOptions = [displayGridOptions.detailOptions];
                detailGridOptions = displayGridOptions.detailOptions;
            }

        var displayColumn = [];

        if (isApplyWorkaroundForMasterSetupExport)
        {
            for (var i = 0; i < displayGridNoOfColumn; i++)
            {
                if (typeof displayGridOptions.columns[i] !== "undefined")
                {
                    if (typeof displayGridOptions.columns[i].field !== "undefined")
                    {
                        if (typeof displayGridOptions.columns[i].field !== "")
                        {
                            if (displayGridOptions.columns[i].hidden !== true)
                            {
                                displayColumn.push(displayGridOptions.columns[i].field.toString());
                            }
                        }
                    }
                }
            }
        }

        var urlObj = _parseUrl(displayGridOptions.dataSource.transport.read.url);
        var expand = urlObj.searchObject.$expand;

        if (expand === undefined)
        {
            expand = "";
        }

        if (typeof detailGridNameForAPIExpand === "string" && detailGridNameForAPIExpand !== "")
        {
            expand = detailGridNameForAPIExpand;
        }

        var res = expand.split(",");

        var select = Object.keys(displayGridOptions.dataSource.schema.model.fields).map(function (field) { return field.replace("_", "/"); });

        if (isApplyWorkaroundForMasterSetupExport)
        {
            for (var j = 0; j < displayColumn.length; j++)
            {
                if (typeof displayColumn[j] !== "undefined")
                {
                    if (select.includes(displayColumn[j].toString()) === false)
                    {
                        select.push(displayColumn[j].toString());
                    }
                }
            }
        }
        
        const index = select.indexOf("IsSelect");
        if (index != -1)
            select.splice(index, 1);
        urlObj.searchObject.$select = select.join();

        if (detailGridOptions) {
            if (detailGridOptions.length > 1) {
                displayGridOptions.detailTemplate = kendo.template('<div kendo-grid></div><div kendo-grid1></div>');
            } else {
                displayGridOptions.detailTemplate = kendo.template('<div kendo-grid></div>');
            }
            

            displayGridOptions.detailInit = function (e) {
                var detailGrid = e.detailRow.find('div[kendo-grid]').kendoGrid(detailGridOptions).data('kendoGrid');
                if (detailGridOptions.length > 1) {
                    var detailGrid1 = e.detailRow.find('div[kendo-grid1]').kendoGrid(detailGridOptions).data('kendoGrid');
                }
            };

            displayGridOptions.detailExpand = function (e) {
                var detailGrid = e.detailRow.find('div[kendo-grid]').data('kendoGrid');
                var dataItem = e.sender.dataItem(e.masterRow);

                var detailData = dataItem[res[0]];

                $rootScope.tempPromise = [];
                //Object.entries(detailGrid.dataSource.options.schema.model.fields).forEach(function (entry) {
                //    var field = entry[0];
                //    var parse = entry[1].parse;

                //    detailData.forEach(function (data) {
                //        data[field] = parse(data[field]);
                //    });
                //});

                detailGrid.dataSource.options.schema = detailGridOptions[0].dataSource.schema;
                detailGrid.dataSource.options.data = detailGridOptions[0].dataSource.data;
                detailGrid.dataSource.options.fields = detailGridOptions[0].columns;
                detailGrid.columns = detailGridOptions[0].columns;

                var fields = detailGrid.dataSource.options.schema.model.fields;

                //Object.keys(fields).forEach(function (field) {
                //    var parse = fields[field].parse;

                //    detailData.forEach(function (data) {
                //        //Usage?
                //        //data[field] = parse(data[field]);
                //    });
                //});

                detailGrid.dataSource.data(detailData);
                
                if (detailGridOptions.length > 1) {
                    detailGrid.bind('excelExport', function (detail) {
                        detail.preventDefault();
                        //dataItem.deferred.resolve(detail.workbook);         //resolve deferred in master row data
                        $rootScope.tempPromise.push(detail.workbook);
                    });

                    detailGrid.saveAsExcel();

                    var detailGrid = e.detailRow.find('div[kendo-grid1]').data('kendoGrid');
                    var dataItem = e.sender.dataItem(e.masterRow);

                    var detailData = dataItem[res[1]];

                    detailGrid.dataSource.options.schema = detailGridOptions[1].dataSource.schema;
                    detailGrid.dataSource.options.data = detailGridOptions[1].dataSource.data;
                    detailGrid.dataSource.options.fields = detailGridOptions[1].columns;
                    detailGrid.columns = detailGridOptions[1].columns;

                    var fields = detailGrid.dataSource.options.schema.model.fields;

                    //Object.keys(fields).forEach(function (field) {
                    //    var parse = fields[field].parse;

                    //    detailData.forEach(function (data) {
                    //        //Usage?
                    //        //data[field] = parse(data[field]);
                    //    });
                    //});

                    detailGrid.dataSource.data(detailData);
                    detailGrid.bind('excelExport', function (detail) {
                        detail.preventDefault();
                        $rootScope.tempPromise.push(detail.workbook);
                        dataItem.deferred.resolve($rootScope.tempPromise);         //resolve deferred in master row data
                        $rootScope.tempPromise = [];
                    });

                    detailGrid.saveAsExcel();
                } else {
                    detailGrid.bind('excelExport', function (detail) {
                        detail.preventDefault();
                        $rootScope.tempPromise.push(detail.workbook);
                        dataItem.deferred.resolve($rootScope.tempPromise);         //resolve deferred in master row data
                        $rootScope.tempPromise = [];
                    });

                    detailGrid.saveAsExcel();
                }
            };

            for (var i = 0; i < detailGridOptions.length; i++) {
                var select = Object.keys(detailGridOptions[i].dataSource.schema.model.fields).map(function (field) { return res[i] + "/" + field; });
                urlObj.searchObject.$select += "," + select.join();

                detailGridOptions[i].columns.forEach(createGroupHeaderTemplate);
                createFormatData(detailGridOptions[i]);

            }

        }

        //var search = Object.entries(urlObj.searchObject).map(function (search) { return search[0] + "=" + search[1]; }).join("&");
        var searchObject = urlObj.searchObject;
        var search = Object.keys(searchObject).map(function (search) { return search + "=" + searchObject[search]; }).join("&");
        var readUrl = urlObj.protocol + "//" + urlObj.host + urlObj.pathname + "?" + search;

        displayGridOptions.dataSource.transport.read.url = readUrl;

        displayGridOptions.columns.forEach(createGroupHeaderTemplate);
        createFormatData(displayGridOptions);

        var filterdet = angular.copy(displayGrid.dataSource.filter());
        if (typeof filterdet != 'undefined')
        {
            for (var i = 0; i < filterdet.filters.length; i++)
            {
                //make sure really is not number
                if (typeof filterdet.filters[i].value == "string" && !_isNumber(filterdet.filters[i].value))
                {
                    filterdet.filters[i].field = "tolower(" + filterdet.filters[i].field + ")";
                    filterdet.filters[i].value = filterdet.filters[i].value.toLowerCase();
                }
            }
        }

        //excel grid datasource options
        var dsOptions = {
            type: displayGridOptions.dataSource.type,
            transport: displayGridOptions.dataSource.transport,
            schema: displayGridOptions.dataSource.schema,
            filter: filterdet, //displayGrid.dataSource.filter(),
            sort: displayGrid.dataSource.sort(),
            group: displayGrid.dataSource.group(),
            serverFiltering: true       //filter data at server side to reduce network
        };
        var ds = new kendo.data.DataSource(dsOptions);

        if (isApplyWorkaroundForMasterSetupExport)
        {
            //second attempt using original url
            ds.options.transport.read.url = displayGrid.dataSource.options.transport.read.url;
            ds.transport.options.read.url = displayGrid.dataSource.options.transport.read.url;
        }
       
        function exportDetail(isReadFromServer, ds) {
            if (isReadFromServer) {
                dsOptions.data = ds.data();

                //KYY: special handling for QC Field Evacuation Detail 
                //column ChitUIDs: array, to be displayed as CSV
                //column CreatedOn: date, to be displayed in time only
                if (dsOptions.data.length) {
                    if (dsOptions.data[0].hasOwnProperty("ChitUIDs") && dsOptions.data[0].hasOwnProperty("CreatedOn")) {
                        dsOptions.data.forEach(p => {
                            if (p.ChitUIDs && p.ChitUIDs.join) {
                                p.ChitUIDs = p.ChitUIDs.join(", ");
                            }
                            if (p.CreatedOn) {
                                p.CreatedOn = moment(p.CreatedOn).format("hh:mm A");
                            }
                        });
                    }
                }
               
            }else{
                dsOptions.data = displayGrid.dataSource.data();
                for (j = dsOptions.data.length - 1; j >= 0; j--) {
                    if (dsOptions.data[j].BSRptGroupDesc == 'P/L Net Profit') {
                        dsOptions.data.remove(dsOptions.data[j]);
                    }
                }

                //patch 1/1/2999 or 1/1/1900 to blank
                if (dsOptions.data.length)
                {
                    if (dsOptions.data[0].hasOwnProperty("DueDate")
                        && dsOptions.data[0].hasOwnProperty("InvoiceDate")
                        && dsOptions.data[0].hasOwnProperty("IssueDateTime"))
                    {
                        dsOptions.data.forEach(p =>
                        {
                            if (p?.DueDate?.getFullYear)
                            {
                                if (!(p.DueDate.getFullYear() > 1900 && p.DueDate.getFullYear() < 2999))
                                {
                                    p.DueDate = "";
                                }
                            }
                            if (p?.InvoiceDate?.getFullYear)
                            {
                                if (!(p.InvoiceDate.getFullYear() > 1900 && p.InvoiceDate.getFullYear() < 2999))
                                {
                                    p.InvoiceDate = "";
                                }
                            }
                            if (p?.IssueDateTime?.getFullYear)
                            {
                                if (!(p.IssueDateTime.getFullYear() > 1900 && p.IssueDateTime.getFullYear() < 2999))
                                {
                                    p.IssueDateTime = "";
                                }
                            }
                        });
                    }
                }
            }
            
            var companyName = "";
            var regNo = "";
            if (dsOptions.data.length > 0) {
                companyName = dsOptions.data[0].CompanyName;
                regNo = dsOptions.data[0].KodMajikan;
            }

            var promises = [];

            var excelDeferred = $q.defer();
            var excelGrid = $("<div id='excelGrid'></div>").kendoGrid({
                dataSource: new kendo.data.DataSource({
                    data: dsOptions.data,
                    sort: dsOptions.sort,
                    group: dsOptions.group,
                }),
                sortable: true,
                groupable: true,
                columns: displayGridOptions.columns,
                detailTemplate: displayGridOptions.detailTemplate,
                detailInit: displayGridOptions.detailInit,
                detailExpand: displayGridOptions.detailExpand,
                dataBound: function (e) {
                    this.saveAsExcel();
                },
                excel: {
                    fileName: displayGridOptions.columns[1].field == "KodMajikan" ? excelFileName.replace(/ /g, "") + ".xlsx" : "ExportData_" + excelFileName.replace(/ /g, "") + "_" + kendo.toString(new Date(), "ddMMyyyy") + ".xlsx",
                    allPages: true
                },
                excelExport: function (excel) {
                    if (displayGridOptions.columns[1].field == "KodMajikan") {
                        var sheet = excel.workbook.sheets[0];
                        sheet.mergedCells = ["C2:E2", "C3:E3", "C8:E8", "C9:E9"];

                        var myHeaders =
                            [{ value: "" },
                            { value: "" },
                            { value: "PERTUBUHAN KESELAMATAN SOSIAL", textAlign: "left", fontSize: 14, bold: true },
                            { value: "" },
                            { value: "" },
                            { value: "Lampiran 1", textAlign: "right", fontSize: 11, bold: true, fontName: "Arial" }];

                        var myHeaders2 =
                            [{ value: "" },
                            { value: "" },
                            { value: "JADUAL CARUMAN BAGI SISTEM INSURANS PEKERJAAN (SIP)", textAlign: "left", fontSize: 14, bold: true },
                            { value: "" },
                            { value: "" },
                            { value: "" }];

                        var myHeaders3 =
                            [{ value: "" },
                            { value: "NAMA MAJIKAN", textAlign: "left", fontSize: 12, bold: true, fontName: "Arial" },
                            { value: companyName, textAlign: "left", fontSize: 12, bold: true, fontName: "Arial" },
                            { value: "" },
                            { value: "" },
                            { value: "" }];

                        var myHeaders4 =
                            [{ value: "" },
                            { value: "KOD MAJIKAN", textAlign: "left", fontSize: 12, bold: true, fontName: "Arial" },
                            { value: regNo, textAlign: "left", fontSize: 12, bold: true, fontName: "Arial" },
                            { value: "" },
                            { value: "" },
                            { value: "" }];

                        sheet.rows.splice(0, 0, { cells: "" });
                        sheet.rows.splice(1, 0, { cells: myHeaders });
                        sheet.rows.splice(2, 0, { cells: myHeaders2 });
                        sheet.rows.splice(3, 0, { cells: "" });
                        sheet.rows.splice(4, 0, { cells: "" });
                        sheet.rows.splice(5, 0, { cells: "" });
                        sheet.rows.splice(6, 0, { cells: "" });
                        sheet.rows.splice(7, 0, { cells: myHeaders3 });
                        sheet.rows.splice(8, 0, { cells: myHeaders4 });
                        sheet.rows.splice(9, 0, { cells: "" });

                        for (j = 0; j < sheet.rows[10].cells.length; j++) {
                            sheet.rows[10].cells[j].fontName = "Arial";
                            sheet.rows[10].cells[j].bold = true;
                            sheet.rows[10].cells[j].background = "#9cc3e6";
                            sheet.rows[10].cells[j].fontSize = 11;
                            sheet.rows[10].cells[j].textAlign = "center";
                            sheet.rows[10].cells[j].color = "#000000";
                        }

                        var total = 0;
                        for (j = 11; j < sheet.rows.length; j++) {
                            total += sheet.rows[j].cells[5].value;
                        }
                        //sheet.mergedCells.push("A" + parseInt(sheet.rows.length + 1) + ":E" + parseInt(sheet.rows.length + 1));
                        //var total = "=SUM(F12:F" + sheet.rows.length + ")";
                        var myHeaders5 =
                            [{ value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "JUMLAH CARUMAN (RM)", textAlign: "right", fontSize: 12, bold: true },
                            { value: total, textAlign: "right", fontSize: 12, bold: true }];

                        sheet.rows.splice(sheet.rows.length, 0, { cells: myHeaders5 });
                    }
                    else if (displayGridOptions.columns[0].field == "EPFNo" && displayGridOptions.columns[1].field == "ICNo" && displayGridOptions.columns[2].field == "EmpyName" && displayGridOptions.columns[3].field == "EmpyID" && displayGridOptions.columns[4].field == "EmprEPF" && displayGridOptions.columns[5].field == "EmpyEPF" && displayGridOptions.columns[6].field == "Wage") {
                        var sheet = excel.workbook.sheets[0];
                        var emprTtl = 0, empyTtl = 0, epfTtl = 0, wagesTtl = 0, ttlEmpy = 0;

                        for (j = 1; j < sheet.rows.length; j++) {
                            emprTtl += sheet.rows[j].cells[4].value;
                            empyTtl += sheet.rows[j].cells[5].value;
                            wagesTtl += sheet.rows[j].cells[6].value;
                            epfTtl += parseInt(sheet.rows[j].cells[0].value);
                            ttlEmpy += 1;
                        }

                        var myFooter =
                            [{ value: epfTtl, textAlign: "left", fontSize: 12, bold: true },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: emprTtl, textAlign: "right", fontSize: 12, bold: true },
                            { value: empyTtl, textAlign: "right", fontSize: 12, bold: true },
                            { value: wagesTtl, textAlign: "right", fontSize: 12, bold: true }];

                        sheet.rows.splice(sheet.rows.length, 0, { cells: myFooter });

                        var myFooter2 =
                            [{ value: ttlEmpy + "record(s)", textAlign: "left", fontSize: 12, bold: true },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "" }];
                        sheet.rows.splice(sheet.rows.length, 1, { cells: myFooter2 });
                    }
                    else if (displayGridOptions.columns[0].field == "TaxNo" && displayGridOptions.columns[1].field == "EmpyName" && displayGridOptions.columns[2].field == "OldIDNo" && displayGridOptions.columns[3].field == "ICNo" && displayGridOptions.columns[4].field == "PassportNo" && displayGridOptions.columns[5].field == "CountryCode" && displayGridOptions.columns[6].field == "TotalMTD" && displayGridOptions.columns[7].field == "TotalCP38" && displayGridOptions.columns[8].field == "EmpyID") {
                        var sheet = excel.workbook.sheets[0];
                        var taxTtl = 0, cp38Ttl = 0, ttlEmpy = 0;

                        for (j = 1; j < sheet.rows.length; j++) {
                            taxTtl += sheet.rows[j].cells[6].value;
                            cp38Ttl += sheet.rows[j].cells[7].value;
                            ttlEmpy += 1;
                        }

                        var myFooter =
                            [{ value: ttlEmpy + "record(s)", textAlign: "left", fontSize: 12, bold: true },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "TOTAL", textAlign: "left", fontSize: 12, bold: true },
                            { value: taxTtl, textAlign: "right", fontSize: 12, bold: true },
                            { value: cp38Ttl, textAlign: "right", fontSize: 12, bold: true },
                            { value: "" }];

                        sheet.rows.splice(sheet.rows.length, 0, { cells: myFooter });
                    }
                    else if (displayGridOptions.columns[0].field == "CompSOCSONo" && displayGridOptions.columns[1].field == "CompRegNo" && displayGridOptions.columns[2].field == "ICNo" && displayGridOptions.columns[3].field == "EmpyName" && displayGridOptions.columns[5].field == "SOCSOAmt") {
                        var sheet = excel.workbook.sheets[0];
                        var socsoTtl = 0, ttlEmpy = 0;

                        for (j = 1; j < sheet.rows.length; j++) {
                            socsoTtl += sheet.rows[j].cells[5].value;
                            ttlEmpy += 1;
                        }

                        var myFooter =
                            [{ value: ttlEmpy + "record(s)", textAlign: "left", fontSize: 12, bold: true },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "TOTAL", textAlign: "left", fontSize: 12, bold: true },
                            { value: socsoTtl, textAlign: "right", fontSize: 12, bold: true },
                            { value: "" },
                            { value: "" }];

                        sheet.rows.splice(sheet.rows.length, 0, { cells: myFooter });
                    }
                    else if (displayGridOptions.columns[0].field == "EmprCode" &&
                        displayGridOptions.columns[1].field == "MyCoID" &&
                        displayGridOptions.columns[2].field == "IDNo" &&
                        displayGridOptions.columns[3].field == "EmpyName" &&
                        displayGridOptions.columns[4].field == "Mth" &&
                        displayGridOptions.columns[5].field == "Salary" &&
                        displayGridOptions.columns[6].field == "EmprSOCSOAmt" &&
                        displayGridOptions.columns[7].field == "EmpySOCSOAmt" &&
                        displayGridOptions.columns[8].field == "EmprEISAmt" &&
                        displayGridOptions.columns[9].field == "EmpyEISAmt")
                    {
                        var sheet = excel.workbook.sheets[0];
                        var socsoEmprTtl = 0, socsoEmpyTtl = 0, eisEmprTtl = 0, eisEmpyTtl = 0,ttlEmpy = 0;

                        for (j = 1; j < sheet.rows.length; j++) {
                            socsoEmprTtl += sheet.rows[j].cells[6].value;
                            socsoEmpyTtl += sheet.rows[j].cells[7].value;
                            eisEmprTtl += sheet.rows[j].cells[8].value;
                            eisEmpyTtl += sheet.rows[j].cells[9].value;
                            ttlEmpy += 1;
                        }

                        var myFooter =
                            [{ value: ttlEmpy + "record(s)", textAlign: "left", fontSize: 12, bold: true },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "" },
                            { value: "TOTAL", textAlign: "left", fontSize: 12, bold: true },
                            { value: socsoEmprTtl, textAlign: "right", fontSize: 12, bold: true },
                            { value: socsoEmpyTtl, textAlign: "right", fontSize: 12, bold: true },
                            { value: eisEmprTtl, textAlign: "right", fontSize: 12, bold: true },
                            { value: eisEmpyTtl, textAlign: "right", fontSize: 12, bold: true }];

                        sheet.rows.splice(sheet.rows.length, 0, { cells: myFooter });
                    }
                    else {
                        if (excelFileName == "RHBEPF" || excelFileName == "RHBSOCSO" || excelFileName == "RHBPAYROLL" || excelFileName == "RHBEIS") {
                            var sheet = excel.workbook.sheets[0];
                            sheet.rows.splice(0, 1);
                        }
                        excel.preventDefault();
                        excelDeferred.resolve(excel);
                    }
                }
            }).data("kendoGrid");
            promises.push(excelDeferred.promise);

            var masterDeferred = $q.defer();
            var masterGrid = $("<div id='masterGrid'></div>").kendoGrid({
                dataSource: new kendo.data.DataSource({
                    data: [displayGridOptions.dataSource.data],
                    group: displayGridOptions.dataSource.group.map(function (obj)
                    {
                        if (isDisableFieldGroupingByUID)
                        {
                            return { field: obj.field };
                        }
                        else
                        {
                            return { field: obj.field + ".uid" };           //group the object uid for that group field
                        }
                    })
                }),
                columns: displayGridOptions.columns,
                groupable: true,
                dataBound: function (e) {
                    this.saveAsExcel();
                },
                excelExport: function (master) {
                    master.preventDefault();
                    masterDeferred.resolve(master);
                }
            }).data("kendoGrid");
            promises.push(masterDeferred.promise);

            if (detailGridOptions) {
                var tempDetailGridOption = detailGridOptions;

                detailGridOptions = tempDetailGridOption[0];

                var detailDeferred = $q.defer();
                var detailGrid = $("<div id='detailGrid'></div>").kendoGrid({
                    dataSource: new kendo.data.DataSource({
                        data: [detailGridOptions.dataSource.data],
                    }),
                    columns: detailGridOptions.columns,
                    groupable: true,
                    dataBound: function (e) {
                        this.saveAsExcel();
                    },
                    excelExport: function (detail) {
                        detail.preventDefault();
                        detailDeferred.resolve(detail);
                    }
                }).data("kendoGrid");
                promises.push(detailDeferred.promise);

                if (detailGridOptions.length > 1) {
                    detailGridOptions = tempDetailGridOption[1];

                    var detailDeferred1 = $q.defer();
                    var detailGrid1 = $("<div id='detailGrid1'></div>").kendoGrid({
                        dataSource: new kendo.data.DataSource({
                            data: [detailGridOptions.dataSource.data],
                        }),
                        columns: detailGridOptions.columns,
                        groupable: true,
                        dataBound: function (e) {
                            this.saveAsExcel();
                        },
                        excelExport: function (detail) {
                            detail.preventDefault();
                            detailDeferred1.resolve(detail);
                        }
                    }).data("kendoGrid");
                    promises.push(detailDeferred1.promise);
                }

                detailGridOptions = tempDetailGridOption;
            }

            $q.all(promises).then(function (e) {
                _formatExcel(e[0], e[1], e[2], e[3], isAlignHeaderCenter, isFormatFooter);
            });
        }

        if (isReadFromServer) {
            ds.read().then(function () {
                exportDetail(isReadFromServer, ds);
            });
        } else {
            exportDetail(isReadFromServer);
        }

    }

    var _formatExcel = function (excel, master, detail, detail2, isAlignHeaderCenter,isFormatFooter) {
        var masterRows, numOfSubgroup, excelData;
        var groupIterator, dataIterator;
        var groupStack = [];
        var promises = [];

        if (detail) {
            masterRows = excel.sender.tbody.find('tr.k-master-row');
            numOfSubgroup = excel.sender.dataSource.group().length;
            excelData = Array.from(excel.data);

            if (numOfSubgroup > 0) {  //if excel grid data is grouped
                groupIterator = excelData.entries();
            }
            else {
                dataIterator = excelData.entries();
            }
        }

        var excelSheet = excel.workbook.sheets[0];
        var formatSheet = master.workbook.sheets[0];
        var dataFormatRows = formatSheet.rows.find(function (row) { return row.type == 'data'; });

        var rows = excelSheet.rows;
        for (var r = 0; r < rows.length; r++) {
            if (detail && rows[r].type == 'group-header') {
                var group = groupIterator.next();

                //traverse back to parent group iterator
                while (group.done && groupStack.length > 0) {
                    groupIterator = groupStack.pop();
                    group = groupIterator.next();
                }

                var groupValue = group.value[1];     //value[0] is index, value[1] is value

                if (groupValue.hasSubgroups) {
                    groupStack.push(groupIterator);
                    groupIterator = groupValue.items.entries();
                }
                else {
                    dataIterator = groupValue.items.entries();
                }
            }
            else if (rows[r].type == 'header') {
                //apply header row formatting
                var cells = rows[r].cells;
                var formatCells = dataFormatRows.cells;
                for (var c = 0; c < cells.length; c++) {
                    if (formatCells[c].value) {
                        if (isAlignHeaderCenter && r == 0 && cells[c].rowSpan == 1) { // for those with header within header, only applicable for first row
                            let headerCustomFormatCell = _.cloneDeep(formatCells[c].value);
                            headerCustomFormatCell.textAlign = 'center';
                            $.extend(cells[c], headerCustomFormatCell);
                        }
                        else {
                            $.extend(cells[c], formatCells[c].value);
                        }
                    }
                }
            }
            else if (rows[r].type == 'data') {
                //apply data row formatting
                var cells = rows[r].cells;
                var formatCells = dataFormatRows.cells;
                for (var c = 0; c < cells.length; c++) {
                    if (formatCells[c].value) {
                        $.extend(cells[c], formatCells[c].value);
                    }
                }

                if (detail) {
                    var data = dataIterator.next();
                    var dataValue = data.value[1];      //value[0] is index, value[1] is value
                    var masterRow = masterRows.filter('[data-uid="' + dataValue.uid + '"]');

                    //detail grid deferred
                    var deferred = $q.defer();
                    promises.push(deferred.promise);

                    excel.sender.dataItem(masterRow).deferred = deferred;  //tag deferred object to master row data
                    excel.sender.expandRow(masterRow);
                }
            }
            else if (rows[r].type == 'footer' && isFormatFooter) { // added flag to prevent interrupt existing printing
                //apply footer row formatting
                var cells = rows[r].cells;
                var formatCells = dataFormatRows.cells;
                for (var c = 0; c < cells.length; c++) {
                    if (formatCells[c].value) {
                        $.extend(cells[c], formatCells[c].value);
                    }
                }
            }
        }

        $q.all(promises).then(function (workbooks) {
            if (workbooks.length > 0) {
                var formatSheet = detail.workbook.sheets[0];
                var dataFormatRows = formatSheet.rows.find(function (row) { return row.type == 'data'; });
                var dataRows = excelSheet.rows.filter(function (row) { return row.type == 'data'; });

                for (var i = workbooks.length - 1; i >= 0; i--) {

                    for (var j = workbooks[i].length - 1; j >= 0; j--) {
                        var rows = workbooks[i][j].sheets[0].rows;
                        for (var r = 0; r < rows.length; r++) {
                            if (rows[r].type == 'header' || rows[r].type == 'data') {
                                //apply header/data row formatting
                                var cells = rows[r].cells;
                                var formatCells = dataFormatRows.cells;
                                for (var c = 0; c < cells.length; c++) {
                                    if (formatCells[c].value) {
                                        if (cells[c].value == "[object Object]" || cells[c].value == null) {
                                            if(c == 0)
                                                cells[c].value = "No Record(s) Found";
                                            else
                                                cells[c].value = "";
                                        }
                                        $.extend(cells[c], formatCells[c].value);
                                    }
                                }
                            }

                            if (numOfSubgroup > 0) {
                                [].splice.apply(rows[r].cells, [0, 0].concat(Array(numOfSubgroup).fill({})));   //indent with empty cells
                            }
                        }

                        var index = excelSheet.rows.indexOf(dataRows[i]);
                        [].splice.apply(excelSheet.rows, [index + 1, 0].concat(rows));
                    }                    
                }
            }    

            var workbook = new kendo.ooxml.Workbook(excel.workbook);
            kendo.saveAs({
                dataURI: workbook.toDataURL(),
                fileName: excel.sender.options.excel.fileName
            });
        });
    }

    function createGroupHeaderTemplate(column) {
        //apply group format to group header template
        if (!column.groupHeaderTemplate) {
            if (column.format) {
                column.groupHeaderTemplate = column.title + ": #= kendo.format('" + column.format + "', value) #";
            }
        }
    }

    function createFormatData(gridOptions) {
        var model = angular.copy(gridOptions.dataSource.schema.model)

        //change all data type to "object"
        Object.values(model.fields).forEach(function (field) {
            switch (field.type) {
                case "number":
                    field.defaultValue = {
                        format: "0",
                        textAlign: "right"
                    };
                    break;
                case "date":
                    field.defaultValue = {
                        format: "dd/MM/yyyy",
                        textAlign: "left"
                    };
                    break;
                    //new number format for PL and BS
                case "spnumber":
                    field.defaultValue = {
                        format: "[$-1010809]#,##0.00;(#,##0.00)",
                        textAlign: "right"
                    };
                    break;
                default:
                    field.defaultValue = {};
            }
            field.type = "object";
        });

        //create format data
        var formatData = new (kendo.data.Model.define(model));
        gridOptions.columns.forEach(function (col) {
            if (col.format) {
                var format = formatParser(col.format);
                if (format) {
                    $.extend(formatData[col.field], format);
                }
            }
            if (col.attributes) {
                if (col.attributes.style) {
                    var style = styleParser(col.attributes.style);
                    if (style) {
                        $.extend(formatData[col.field], style);
                    }
                }
            }
        });

        gridOptions.dataSource.data = formatData;
    }

    function formatParser(formatString) {
        var cell = {};

        var format = formatString.match(/\s*{\s*0\s*:\s*(.*)\s*\}\s*/)[1];  //retrieve format from '{0:format}'

        if (format.startsWith("n")) {
            var numOfZero = parseInt(format.substring(1));
            cell.format = "#,##0" + (numOfZero ? "." + ("0").repeat(numOfZero) : "");   //repeat # number of zero in n#
        } else if (format == "dd/MM/yyyy") {
            cell.format = format;
        } else if (format == "MMMM yyyy") {
            cell.format = format;
        }
        else if (format.startsWith("z")) {
            var numOfZero = parseInt(format.substring(1));
            cell.format = "###0" + (numOfZero ? "." + ("0").repeat(numOfZero) : "");   //repeat # number of zero in n#
            //else {    /* Define other format if necessary */

            //}
        }

        return cell;
    }

    function styleParser(styleString) {
        var cell = {};

        styleString.split(";").forEach(function (style) {

            var textAlign = style.match(/\s*text-align\s*:\s*(.*)\s*/);  //retrieve format from 'text-align:[left|right|center]'
            if (textAlign) {
                cell.textAlign = textAlign[1];
            }

            //var background = style.match(/\s*background-color\s*:\s*(.*)\s*/);   //retrieve format from 'background-color:#XXXXXX'
            //if (background) {
            //    cell.background = background[1];
            //}

            //if {    /* Define other format if necessary */

            //}
        });

        return cell;
    }

    excelExportServiceFactory.exportToExcel = _exportToExcel;

    return excelExportServiceFactory;
}]);